const anchorsModule = () => {

    const toTopAnchor = document.querySelector('[data-anchor-to="top"]');
    const topBlock = document.getElementById('top');

    //Add event click (scroll to top) for to top btn
    toTopAnchor.addEventListener("click", (e) => {
        e.preventDefault();
        topBlock.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    });

}

export {anchorsModule};