const headerList = () => {

    const fullTimeOfModalAnimation = 401;
    const ListBtns = document.querySelectorAll('[data-menu-item="with-list"]');
    const NumbersListBtns = document.querySelectorAll('[data-lists-btn="list-numbers"]');
    const childListBtns = document.querySelectorAll('[data-menu-item="with-child-list"]');

    //Number lists logic
    for (const NumbersListBtn of NumbersListBtns) {
        const listItem = NumbersListBtn.nextElementSibling;

        NumbersListBtn.addEventListener('click', () => {
            //close the others number lists before open new
            const otherElements = document.querySelectorAll('[data-lists-btn="list-numbers"]');

            for (const otherElement of otherElements) {
                if (otherElement != NumbersListBtn && otherElement.classList.contains('active')) {
                    const listItem = otherElement.nextElementSibling;
                    
                    otherElement.classList.remove('active');

                    //close
                    listItem.classList.remove('opened');
                    setTimeout(() => {
                        listItem.classList.remove('db');
                    }, 400);
                }
            }

            //close the others lists before open new
            for (const ListBtn of ListBtns) {
                if (ListBtn.classList.contains('active')) {
                    const listItem = ListBtn.querySelector('[data-lists-btn="menu-list"]');

                    ListBtn.classList.remove('active');

                    //close child lists
                    for (const childListBtn of childListBtns) {
                        const childListItem = childListBtn.querySelector('[data-lists-btn="menu-child-list"]');

                        if(childListItem.classList.contains('db') || childListItem.classList.contains('opened')) {
                            childListBtn.classList.remove('active');
                            childListItem.classList.remove('opened');
                            setTimeout(() => {
                                childListItem.classList.remove('db');
                            }, 400);
                        }
                    }
                    //close
                    listItem.classList.remove('opened');
                    setTimeout(() => {
                        listItem.classList.remove('db');
                    }, 400);
                }
            }

            if (!NumbersListBtn.classList.contains('disabled')) {
                NumbersListBtn.classList.toggle('active');
                if (NumbersListBtn.classList.contains('active')) {
                    //open
                    listItem.classList.add('db');
                    setTimeout(() => {
                        listItem.classList.add('opened');
                    }, 1);
                } else {
                    //close
                    listItem.classList.remove('opened');
                    setTimeout(() => {
                        listItem.classList.remove('db');
                    }, 400);
                }

                //disabling button for waiting of modal animation
                NumbersListBtn.classList.add('disabled');
                setTimeout(() => {
                    NumbersListBtn.classList.remove('disabled');
                }, fullTimeOfModalAnimation);
            }
        });
    }

    //Child menu list logic
    for (const childListBtn of childListBtns) {
        const listItem = childListBtn.querySelector('[data-lists-btn="menu-child-list"]');

        childListBtn.addEventListener('click', (e) => {
            e.stopPropagation();

            //close the others lists before open new
            const otherElements = childListBtn.parentNode.children;
            for (const otherElement of otherElements) {
                if (otherElement != childListBtn && otherElement.classList.contains('active')) {
                    const listItem = otherElement.querySelector('[data-lists-btn="menu-child-list"]');
                    
                    otherElement.classList.remove('active');

                    //close
                    listItem.classList.remove('opened');
                    setTimeout(() => {
                        listItem.classList.remove('db');
                    }, 400);
                }
            }

            if (!childListBtn.classList.contains('disabled')) {
                childListBtn.classList.toggle('active');
                if (childListBtn.classList.contains('active')) {
                    //open
                    listItem.classList.add('db');
                    setTimeout(() => {
                        listItem.classList.add('opened');
                    }, 1);
                } else {
                    //close
                    listItem.classList.remove('opened');
                    setTimeout(() => {
                        listItem.classList.remove('db');
                    }, 400);
                }
            }

            //disabling button for waiting of modal animation
            childListBtn.classList.add('disabled');
            setTimeout(() => {
                childListBtn.classList.remove('disabled');
            }, fullTimeOfModalAnimation);
        });
    }

    //Menu lists logic
    for (const ListBtn of ListBtns) {
        const listItem = ListBtn.querySelector('[data-lists-btn="menu-list"]');

        ListBtn.addEventListener('click', () => {
            //close the others lists before open new
            const otherElements = ListBtn.parentNode.children;
            for (const otherElement of otherElements) {
                if (otherElement != ListBtn && otherElement.classList.contains('active')) {
                    const listItem = otherElement.querySelector('[data-lists-btn="menu-list"]');
                    
                    otherElement.classList.remove('active');

                    //close child lists
                    for (const childListBtn of childListBtns) {
                        const childListItem = childListBtn.querySelector('[data-lists-btn="menu-child-list"]');

                        if(childListItem.classList.contains('db') || childListItem.classList.contains('opened')) {
                            childListBtn.classList.remove('active');
                            childListItem.classList.remove('opened');
                            setTimeout(() => {
                                childListItem.classList.remove('db');
                            }, 400);
                        }
                    }
                    //close
                    listItem.classList.remove('opened');
                    setTimeout(() => {
                        listItem.classList.remove('db');
                    }, 400);
                }
            }

            //close the others number lists before open new
            for (const NumbersListBtn of NumbersListBtns) {
                if (NumbersListBtn.classList.contains('active')) {
                    const listItem = NumbersListBtn.nextElementSibling;

                    NumbersListBtn.classList.remove('active')

                    //close
                    listItem.classList.remove('opened');
                    setTimeout(() => {
                        listItem.classList.remove('db');
                    }, 400);
                }
            }
            
            if (!ListBtn.classList.contains('disabled')) {
                ListBtn.classList.toggle('active');
                if (ListBtn.classList.contains('active')) {
                    //open
                    listItem.classList.add('db');
                    setTimeout(() => {
                        listItem.classList.add('opened');
                    }, 1);
                } else {
                    //close child lists
                    for (const childListBtn of childListBtns) {
                        const childListItem = childListBtn.querySelector('[data-lists-btn="menu-child-list"]');

                        if(childListItem.classList.contains('db') || childListItem.classList.contains('opened')) {
                            childListBtn.classList.remove('active');
                            childListItem.classList.remove('opened');
                            setTimeout(() => {
                                childListItem.classList.remove('db');
                            }, 400);
                        }
                    }
                    //close
                    listItem.classList.remove('opened');
                    setTimeout(() => {
                        listItem.classList.remove('db');
                    }, 400);
                }
            }

            //disabling button for waiting of modal animation
            ListBtn.classList.add('disabled');
            setTimeout(() => {
                ListBtn.classList.remove('disabled');
            }, fullTimeOfModalAnimation);
        });
    }

    //closing lists
    document.addEventListener('click', (e) => {
        if (!e.target.classList.contains('menu__item') && !e.target.classList.contains('fa-angle-down') && !e.target.classList.contains('fa-angle-down') && !e.target.classList.contains('menu__item-child') && !e.target.classList.contains('list-child-link') && !e.target.classList.contains('number__top') && !e.target.classList.contains('link')) {
            for (const ListBtn of ListBtns) {
                if (ListBtn.classList.contains('active')) {
                    const listItem = ListBtn.querySelector('[data-lists-btn="menu-list"]');

                    ListBtn.classList.remove('active');

                    //close child lists
                    for (const childListBtn of childListBtns) {
                        const childListItem = childListBtn.querySelector('[data-lists-btn="menu-child-list"]');

                        if(childListItem.classList.contains('db') || childListItem.classList.contains('opened')) {
                            childListBtn.classList.remove('active');
                            childListItem.classList.remove('opened');
                            setTimeout(() => {
                                childListItem.classList.remove('db');
                            }, 400);
                        }
                    }
                    //close
                    listItem.classList.remove('opened');
                    setTimeout(() => {
                        listItem.classList.remove('db');
                    }, 400);
                }
            }

            for (const NumbersListBtn of NumbersListBtns) {
                if (NumbersListBtn.classList.contains('active')) {
                    const listItem = NumbersListBtn.nextElementSibling;

                    NumbersListBtn.classList.remove('active')

                    //close
                    listItem.classList.remove('opened');
                    setTimeout(() => {
                        listItem.classList.remove('db');
                    }, 400);
                }
            }
        }
    });

}

export {headerList};