import LazyLoad from "vanilla-lazyload";
import {anchorsModule} from "./modules/anchors";
import {headerList} from "./modules/headerLists";
import {mobileMenu} from './modules/mobileMenu';

//Including lazy loading
const lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
});
lazyLoadInstance.update();

//Add Async loading for not important JS files
document.addEventListener("DOMContentLoaded", () => {
  
  //UI modules
  anchorsModule();
  headerList();
  mobileMenu();

});