const mobileMenu = () => {

    const mobileMenuOpenBtn = document.querySelector('[data-mobile-components="menu-open-btn"]');
    const mobileMenuCloseBtn = document.querySelector('[data-mobile-components="menu-close-btn"]');
    const mobileMenu = document.querySelector('[data-mobile-components="menu"]');
    const fullTimeOfModalAnimation = 401;

    //Open menu btn
    mobileMenuOpenBtn.addEventListener('click', () => {
        if (!mobileMenuOpenBtn.classList.contains('disabled')) {
            mobileMenu.classList.add('db');
            setTimeout(() => {
                document.body.classList.add('overflow-hidden');
                mobileMenu.classList.add('opened');
            }, 1);

            //disabling button for waiting of modal animation
            mobileMenuOpenBtn.classList.add('disabled');
            mobileMenuCloseBtn.classList.add('disabled');
            setTimeout(() => {
                mobileMenuOpenBtn.classList.remove('disabled');
                mobileMenuCloseBtn.classList.remove('disabled');
            }, fullTimeOfModalAnimation);
        }
    });

    //Close menu btn
    mobileMenuCloseBtn.addEventListener('click', () => {
        if (!mobileMenuCloseBtn.classList.contains('disabled')) {
            mobileMenu.classList.remove('opened');
            setTimeout(() => {
                document.body.classList.remove('overflow-hidden');
                mobileMenu.classList.remove('db');
            }, 400);

            //disabling button for waiting of modal animation
            mobileMenuOpenBtn.classList.add('disabled');
            mobileMenuCloseBtn.classList.add('disabled');
            setTimeout(() => {
                mobileMenuOpenBtn.classList.remove('disabled');
                mobileMenuCloseBtn.classList.remove('disabled');
            }, fullTimeOfModalAnimation);
        }
    });

}

export {mobileMenu};